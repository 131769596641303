<template>
    <div class="box" id="ygh-content-box">
        <div class="big">
            <div class="box-btns">
                <a-button
                    :type="btck == i ? 'primary' : ''"
                    icon="file-sync"
                    class="btns"
                    v-for="(o, i) in btna"
                    :key="i"
                    @click="change_status(i)"
                    >{{ o }}</a-button
                >
            </div>
            <div>
                <a-card :bordered="false">
                    <s-table
                        ref="table"
                        :columns="columns"
                        :data="loadData"
                        :alert="false"
                        :rowKey="(record) => record.id"
                    >
                        <span slot="operator">
                            <div style="display: flex">
                                <div class="flex operatorContent">
                                    <div>
                                        <a-input-search
                                            placeholder="请输入关键字"
                                            @search="onSearch"
                                            class="search"
                                            v-model="queryParam.name"
                                        >
                                            <a-button slot="enterButton" icon="search"> </a-button>
                                        </a-input-search>
                                    </div>
                                    <div style="display: flex">
                                        <div>
                                            <a-button
                                                style="background: none; color: #40a9ff"
                                                type="primary"
                                                shape="round"
                                                icon="plus"
                                                @click="$refs.stepsForm.open({ type: 'add' })"
                                            >
                                                发起登记
                                            </a-button>
                                        </div>
                                        <!-- <div v-if="btck == 0" style="margin-left: 20px;">
                                                  <a-button
                                                  style="background: none;color: #40a9ff;"
                                                  type="primary"
                                                  icon="audit"
                                                  shape="round"
                                                  @click="todoApproval">
                                                    发起审批
                                                  </a-button>
                                                </div> -->
                                    </div>
                                </div>
                            </div>
                        </span>
                        <span slot="documentType" slot-scope="text">
                            <span v-if="text == +1">收文登记</span>
                            <span v-else-if="text == +2">快速发文</span>
                            <span v-else-if="text == +3">拟稿发文</span>
                        </span>
                        <span slot="draftId" slot-scope="text, record">
                            <div v-for="(o, i) in record.draftId" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
                                </a-tag>
                            </div>
                        </span>
                        <span slot="sendIds" slot-scope="text, record">
                            <div v-for="(o, i) in record.sendIds" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
                                </a-tag>
                            </div>
                        </span>
                        <span slot="acceptTime" slot-scope="text">
                            {{ text && text.substr(0, 10) }}
                        </span>
                        <span slot="urgencyDegree" slot-scope="text" :style="flowUrgencyDegree(text)">
                            {{ Filter(flow_urgency_degree, text) }}
                        </span>
                        <span slot="secrecyDegree" slot-scope="text" :style="flowSecrecyDegree(text)">
                            {{ Filter(flow_secrecy_degree, text) }}
                        </span>
                        <span slot="approvalStatus" slot-scope="text" :class="text != 2 ? 'fred' : ''">
                            {{ Filter(flow_approval_status, text) }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a class="bts" @click="see(record)">查看</a>
                            <template v-if="record.approvalStatus == 0">
                                <a class="bts" @click="addApproval(record)">发起审批</a>
                            </template>
                            <!-- 登记,驳回 -->
                            <template v-if="[3].includes(+record.approvalStatus)">
                                <a-popconfirm
                                    placement="topRight"
                                    title="审批流程将从第一步开始进行审批"
                                    @confirm="() => retrial(record)"
                                >
                                    <a class="bts">修改重审</a>
                                </a-popconfirm>
                                <!-- <a-popconfirm
                                placement="topRight"
                                title="审批流程将从驳回人这重新开始继续审批"
                                @confirm="() => renewal(record)"
                                >
                                <a class="bts">修改续审</a>
                                </a-popconfirm> -->
                            </template>
                            <template v-if="[0].includes(+record.approvalStatus)">
                                <a class="bts" @click="edit(record)">修改</a>
                                <a-popconfirm
                                    placement="topRight"
                                    title="确认删除？"
                                    @confirm="() => singleDelete(record)"
                                >
                                    <a class="bts">删除</a>
                                </a-popconfirm>
                            </template>
                            <a
                                class="bts"
                                v-if="[2, 4].includes(record.approvalStatus) && record.recipientStatus !== 1"
                                @click="deal(record)"
                                >{{ record.approvalStatus === 4 ? '处理公文审批' : '公文下发' }}</a
                            >
                            <!-- <a class="bts" v-if="record.approvalStatus == 1" @click="senpi(record)">审批</a>
              <a class="bts" v-if="record.approvalStatus == 2">加签</a> -->
                        </span>
                    </s-table>
                </a-card>
                <steps-form ref="stepsForm" @ok="handleOk" @refresh="refresh" />
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line camelcase
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { delDocumentBill, documentBillPage } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import { getDefaultColumns } from './config'
import stepsForm from './stepsForm.vue'
export default {
    name: 'list',
    components: {
        STable,
        OpenData,
        stepsForm,
    },
    mixins: [stateColor],
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '未完成', '已完成'],
            btck: 0,
            // 查询参数
            //queryParam: { approvalStatus: 0, documentType: 1 },
            queryParam: { documentType: 1, approvalTab: 0 },
            // 表头
            columns: [],
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                const { approvalStatus } = parameter
                let params = { ...parameter }
                if (approvalStatus && approvalStatus.length > 0)
                    params = { ...parameter, approvalStatus: approvalStatus[0] }
                return documentBillPage(Object.assign(params, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        ele.sendIds = formatDraftId(ele.sendIds)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    computed: {},
    beforeDestroy() {
        this.$store.commit('bill/setRegistration', false)
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66)
    },
    methods: {
        handleOk() {},
        refresh() {
            this.$refs.table.refresh()
        },
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
                if (res.data) this.columns = getDefaultColumns(res.data)
            })
        },
        todoApproval() {
            console.log(this.selectedRowKeys)
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        change_status(i) {
            this.btck = i
            this.columns = getDefaultColumns(this.flow_approval_status, i === 0)
            this.queryParam.approvalTab = i
            this.$refs.table.refresh(true)
        },
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        edit(record) {
            this.$refs.stepsForm.open({ type: 'edit', id: record.id })
            // this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        // 续审
        renewal(record) {
            this.$refs.stepsForm.open({ type: 'renewal', id: record.id })
        },
        // 重审
        retrial(record) {
            this.$refs.stepsForm.open({ type: 'retrial', id: record.id })
        },
        addApproval(record) {
            this.$refs.stepsForm.open({ type: 'approval', id: record.id })
        },
        senpi(record) {
            this.$router.push({ path: '/fileStream_Approval_Manage', query: { id: record.id } })
        },
        deal(record) {
            this.$router.push({ path: '/fileStream_Approval_process', query: { id: record.id, todo: 'dealWith' } })
        },
        see(record) {
            // this.$refs.stepsForm.open({ type: 'edit', id: record.id })
            this.$router.push({ path: '/fileStream_Approval_process', query: { id: record.id } })
        },
    },
}
</script>

<style lang="less" scoped>
.box {
    // width: 1280px;
    height: calc(100% - 17px - 24px);
    margin: auto !important;
    background: #ffffff;
    border-radius: 4px;

    padding: 33px 39px;
    position: relative;

    .big {
        button {
            font-size: 16px;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;
    }

    .search {
        width: 300px;
        border-radius: 4px;
    }
}

::v-deep .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
}

::v-deep .ant-card-body {
    padding: 0;
}

.btns {
    margin-right: 17px;
}

.fred {
    color: #f56c6c;
}

.bts {
    margin-right: 10px;
}

.operatorContent {
    margin: 0 !important;
    justify-content: space-between;
    width: 100%;
    height: 47px;
    align-items: flex-end;
}

.flex0 {
    display: flex;
    align-items: center;
}

.my-item {
    display: inline-block;

    .tags {
        margin-bottom: 1px;
    }
}
</style>
